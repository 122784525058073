import React, { Dispatch, SetStateAction } from "react";
import { Form } from "react-bootstrap";
import { LoginFormData } from "../interfaces/LoginFormData";

export default function LoginForm({
  values,
  setValues,
}: {
  values: LoginFormData;
  setValues: Dispatch<SetStateAction<LoginFormData>>;
}) {
  return (
    <>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Email address</Form.Label>
        <Form.Control
          onChange={(data) =>
            setValues((prev) => ({
              ...prev,
              username: data.target.value,
            }))
          }
          type="username"
          placeholder="Enter email"
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Password</Form.Label>
        <Form.Control
          onChange={(data) =>
            setValues((prev) => ({
              ...prev,
              password: data.target.value,
            }))
          }
          type="password"
          placeholder="Password"
        />
      </Form.Group>
    </>
  );
}
