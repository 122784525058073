import axios from "axios";
import browserSignature from "browser-signature";

const signature = browserSignature();
export const setAxiosEnvironment = () => {
  // axios.defaults.withCredentials = true;
  axios.defaults.headers.common["X-BROWSER-FINGER-PRINT"] = signature;
  axios.defaults.baseURL = "/api";
  axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.defaults.headers.common.Accept = "application/json";
};
