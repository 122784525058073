import React from "react";
import { useImageContext } from "../../contexts/ImageContext";

export default function ImageSelectionAccordion() {
  const { setImage, imageDimension } = useImageContext();

  return (
    <div className="text-nowrap">
      <input
        className="custom-file-input"
        onChange={(event) => {
          const target = event.target as HTMLInputElement;
          target.files?.[0] && setImage(target.files?.[0]);
        }}
        type="file"
        id="backgroundPicker"
        title=""
      />
      {imageDimension.height > 0 && (
        <small>
          Velikost obázku: {imageDimension.width}x{imageDimension.height}px
        </small>
      )}
    </div>
  );
}
