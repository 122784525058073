import { v4 as uuidv4 } from "uuid";
import { SpecialElementType } from "../SpecialElement";

export interface TemplateResponse {
  data: TemplateData;
}

export interface TemplateData {
  list: TemplateDataItem[];
}

export interface TemplateDataItem {
  id: string;
  templateType: string;
  width: number;
  height: number;
  categoryDesign?: Font;
  subcategoryDesign?: Font;
  itemDesign?: Font;
  frontPage?: Page;
  backPage?: Page;
}

export interface Page {
  id: string;
  type: "FRONT" | "BACK";
  backgroundImagePath?: string;
  backgroundImageFile?: File;
  margins: Margins;
  columns: Column[];
  specialElements: SpecialElement[];
}

export const getDefaultTemplatePage = (data: Partial<Page>): Page => ({
  id: uuidv4(),
  type: "FRONT",
  backgroundImagePath: undefined,
  margins: { top: 8, right: 0, bottom: 8, left: 6 },
  columns: [],
  specialElements: [],
});

export interface Coordinates {
  topLeftX: number;
  topLeftY: number;
  width: number;
  height: number;
}

export interface Column extends Coordinates {
  id: string;
  order: number;
}

export interface Margins {
  top: number;
  right: number;
  bottom: number;
  left: number;
}

export interface SpecialElement extends Coordinates, Font {
  id: string;
  specialElementType: SpecialElementType;
  imageExample?: File;
  items: Item[];
}

export interface Item {
  id: string;
  image: string;
  headline: string;
}

export type Font = {
  font?: string;
  fontFamily: string;
  fontSize: number;
  color: string;
  margin?: number;
  isCapital: boolean;
  leftMargin: number;
  textAlignment?: "left" | "right" | "center";
};

export const getDefaultFont = () => ({
  font: "",
  fontFamily: "Knockout48",
  fontSize: 12,
  color: "#FFFFFF",
  margin: 0,
  isCapital: true,
  leftMargin: 0,
});

export const getDefaultTemplate = (
  data?: Partial<TemplateDataItem>
): TemplateDataItem => ({
  id: uuidv4(),
  templateType: "DRINK",
  width: 154,
  height: 216,
  categoryDesign: {
    font: "",
    fontFamily: "Knockout48",
    fontSize: 12,
    color: "#FFFFFF",
    margin: 0,
    isCapital: true,
    leftMargin: 0,
  },
  subcategoryDesign: {
    font: "",
    fontFamily: "Knockout48",
    fontSize: 11,
    color: "#FFFFFF",
    margin: 0,
    isCapital: true,
    leftMargin: 0,
  },
  itemDesign: {
    fontFamily: "Knockout48",
    fontSize: 10,
    color: "#FFFFFF",
    isCapital: false,
    leftMargin: 0,
  },
  frontPage: {
    id: uuidv4(),
    type: "FRONT",
    backgroundImagePath: undefined,
    margins: { top: 8, right: 0, bottom: 8, left: 6 },
    columns: [],
    specialElements: [],
  },
  backPage: {
    id: uuidv4(),
    type: "BACK",
    backgroundImagePath: undefined,
    margins: { top: 8, right: 0, bottom: 8, left: 0 },
    columns: [],
    specialElements: [],
  },
  ...data,
});

export enum FontFamily {
  DINPROBOLD = "DINPRO-BOLD",
  DINPROMEDIUM = "DINPRO-MEDIUM",
  DosisSemiBold = "Dosis-SemiBold",
  FuturaTOTLigh = "FuturaTOT-Ligh",
  FuturaTOTMedi = "FuturaTOT-Medi",
  GestaMedium = "Gesta-Medium",
  GothamCondBook = "GothamCond-Book",
  HelveticaNeueLTProBdCn = "HelveticaNeueLTPro-BdCn",
  HelveticaNeueLTProLtCn = "HelveticaNeueLTPro-LtCn",
  insigneAvianoSansBlack = "insigne-AvianoSansBlack",
  insigneAvianoSansBold = "insigne-AvianoSansBold",
  insigneAvianoSansRegular = "insigne-AvianoSansRegular",
  JohnSansTextProBold = "JohnSans-Text-Pro-Bold",
  JohnSansTextPro = "JohnSans-Text-Pro",
  KlavikaRegular = "Klavika-Regular",
  Knockout48_1 = "Knockout-48_1",
  Knockout68_1 = "Knockout-68_1",
  Komu_A = "Komu_A",
  neutraface = "neutraface",
  Neutraface2TextLight = "Neutraface2Text-Light",
  Town20InlineBlack = "Town 20 Inline Black",
}
