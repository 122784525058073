import React from "react";
import { Button } from "react-bootstrap";
import { useApiContext } from "../contexts/ApiContext";
import { useImageContext } from "../contexts/ImageContext";

type Props = {};

export default function LeftNavbar({}: Props) {
  const { uploadFont, getFont, templatesData } = useApiContext();
  const { setPageSettings } = useImageContext();

  return (
    <div className="left-navbar">
      {templatesData.map((data) => {
        return <Button onClick={() => setPageSettings(data)}>{data.id}</Button>;
      })}
    </div>
  );
}
