import * as React from "react";
import { useState } from "react";
import { Button, Container, Modal, Navbar, NavDropdown } from "react-bootstrap";
import { useApiContext } from "../contexts/ApiContext";
import { LoginFormData } from "../interfaces/LoginFormData";
import LoginForm from "./LoginForm";

function Header() {
  const { user, login, logout } = useApiContext();
  const [values, setValues] = useState<LoginFormData>({
    username: "",
    password: "",
  });
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleLogin = () => {
    if (values.password.length > 2 && values.username.length > 2) {
      login(values);
      handleClose();
    }
  };

  return (
    <Navbar className="header-navbar">
      <Container>
        <Navbar.Brand href="#home">
          <img
            src={process.env.PUBLIC_URL + "/assets/logo.png"}
            className="d-inline-block align-top"
            alt="React Bootstrap logo"
          />
        </Navbar.Brand>

        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text>
            {user ? (
              <NavDropdown title={user.userId} id="basic-nav-dropdown">
                <NavDropdown.Item href="#">Something</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={logout}>Logout</NavDropdown.Item>
              </NavDropdown>
            ) : (
              <Button variant="outline-dark" onClick={handleShow}>
                Login
              </Button>
            )}
          </Navbar.Text>
        </Navbar.Collapse>
      </Container>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LoginForm values={values} setValues={setValues} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleLogin}>
            Login
          </Button>
        </Modal.Footer>
      </Modal>
    </Navbar>
  );
}

export default Header;
