import React, { ChangeEvent } from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import { useImageContext } from "../../contexts/ImageContext";
import { Margins, Page } from "../../interfaces/api/TemplateResponse";

export interface BordersProps {
  currentPage: Page;
}

export default function BordersAccordion({ currentPage }: BordersProps) {
  const { setPageSettings, page } = useImageContext();
  const handleChange =
    (index: keyof Margins) => (evt: ChangeEvent<HTMLInputElement>) => {
      if (currentPage) {
        const item = evt.target.validity.valid ? evt.target.value : undefined;
        if (item) {
          const parsedItem = parseInt(item, 10);
          const newBorders: Margins = {
            ...currentPage.margins,
            [index]: parsedItem,
          };

          const newPage = { ...currentPage, margins: newBorders };

          setPageSettings({ [page]: newPage });
        }
      }
    };

  return (
    <InputGroup size="sm" className="flex-row w-100">
      <InputGroup.Text className="group-title">Tiskové okraje</InputGroup.Text>
      <InputGroup.Text>Horní</InputGroup.Text>
      <FormControl
        className="paddings paddings-top"
        type="number"
        pattern="[0-9]*"
        onChange={handleChange("top")}
        value={currentPage.margins.top}
      />
      <InputGroup.Text>Levý</InputGroup.Text>
      <FormControl
        className="paddings paddings-left"
        type="number"
        pattern="[0-9]*"
        onChange={handleChange("left")}
        value={currentPage.margins.left}
      />
      <InputGroup.Text>Pravý</InputGroup.Text>
      <FormControl
        className="paddings paddings-right"
        type="number"
        pattern="[0-9]*"
        onChange={handleChange("right")}
        value={currentPage.margins.right}
      />
      <InputGroup.Text>Spodní</InputGroup.Text>
      <FormControl
        className="paddings paddings-bottom"
        type="number"
        pattern="[0-9]*"
        onChange={handleChange("bottom")}
        value={currentPage.margins.bottom}
      />
    </InputGroup>
  );
}
