import React, { useEffect, useRef } from "react";
import { Rnd } from "react-rnd";
import { useImageContext } from "../contexts/ImageContext";
import { SpecialElementType } from "../interfaces/SpecialElement";
import "../styles/image-styles.css";
import "../styles/settings-styles.css";
import BordersAccordion from "./accordions/BordersAccordion";
import ColumnsAccordion from "./accordions/ColumnsAccordion";
import ImageSelectionAccordion from "./accordions/ImageSelectionAccordion";
import PaperAccordion from "./accordions/PaperAccordion";
import SpecialFiledAccordion from "./accordions/SpecialFiledAccordion";
import ProductRow from "./ProductRow";

interface Props {}

function MainContent(props: Props) {
  const {
    pageSettings,
    setPageSettings,
    page,
    setSpecialElement,
    imageDimension,
    setBoardDimension,
    boardDimension,
    setPage,
    setColumn,
  } = useImageContext();

  const createNewTemplate = () => {
    setPageSettings({});
  };

  const currentPage = pageSettings?.[page];
  const imgRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (imageDimension) {
      const oWidth = imgRef.current?.offsetWidth;
      const oHeight = imgRef.current?.offsetHeight;
      if (oWidth && oHeight) {
        const imgRatio = imageDimension.width / imageDimension.height;
        const divRatio = oWidth / oHeight;
        if (imgRatio < divRatio) {
          setBoardDimension({
            width: (oHeight / imageDimension.height) * imageDimension.width,
            height: oHeight,
          });
        } else {
          setBoardDimension({
            width: oWidth,
            height: (oWidth / imageDimension.width) * imageDimension.height,
          });
        }
      }
    }
  }, [imageDimension, setBoardDimension]);

  return (
    <div className="main-content">
      <div className="settings-container">
        <PaperAccordion />
        {currentPage && (
          <>
            <div className="flex-row">
              <ImageSelectionAccordion />
              <BordersAccordion currentPage={currentPage} />
            </div>
            <ColumnsAccordion currentPage={currentPage} />
            <SpecialFiledAccordion currentPage={currentPage} />
          </>
        )}
      </div>
      <div className="image-container">
        {currentPage && currentPage.backgroundImageFile && (
          <>
            <img
              ref={imgRef}
              src={URL.createObjectURL(currentPage.backgroundImageFile)}
              width="100%"
              alt="Selected "
            />
            <div
              className="image-border"
              style={{
                width:
                  boardDimension.width -
                  currentPage.margins.left -
                  currentPage.margins.right,
                height:
                  boardDimension.height -
                  currentPage.margins.top -
                  currentPage.margins.bottom,
                left: currentPage.margins.left,
                top: currentPage.margins.top,
              }}
            ></div>

            {currentPage.columns?.map((column) => (
              <Rnd
                key={column.id}
                className="image-draggable-container"
                size={{ width: column.width, height: column.height }}
                position={{ x: column.topLeftX, y: column.topLeftY }}
                bounds="parent"
                onDragStop={(e, data) => {
                  setColumn({ ...column, topLeftX: data.x, topLeftY: data.y });
                }}
                onResizeStop={(e, direction, ref, delta, position) => {
                  setColumn({
                    ...column,
                    topLeftX: position.x,
                    topLeftY: position.y,
                    width: parseInt(ref.style.width, 10),
                    height: parseInt(ref.style.height, 10),
                  });
                }}
              >
                <div
                  style={{
                    marginBottom: pageSettings.categoryDesign?.margin,
                  }}
                >
                  <p
                    className="column-header"
                    style={{
                      fontFamily: pageSettings.categoryDesign?.fontFamily,
                      fontSize: pageSettings.categoryDesign?.fontSize,
                      color: pageSettings.categoryDesign?.color,
                      textTransform: pageSettings.categoryDesign?.isCapital
                        ? "uppercase"
                        : "none",
                    }}
                  >
                    Nadpis
                  </p>
                  {pageSettings.itemDesign && (
                    <>
                      <ProductRow font={pageSettings.itemDesign} index={1} />
                      <ProductRow font={pageSettings.itemDesign} index={2} />
                      <ProductRow font={pageSettings.itemDesign} index={3} />
                      <ProductRow font={pageSettings.itemDesign} index={4} />
                    </>
                  )}
                </div>
                <div
                  style={{
                    marginBottom: pageSettings.categoryDesign?.margin,
                  }}
                >
                  <p
                    className="column-header"
                    style={{
                      fontFamily: pageSettings.categoryDesign?.fontFamily,
                      fontSize: pageSettings.categoryDesign?.fontSize,
                      color: pageSettings.categoryDesign?.color,
                      textTransform: pageSettings.categoryDesign?.isCapital
                        ? "uppercase"
                        : "none",
                    }}
                  >
                    Nadpis
                  </p>
                  {pageSettings.itemDesign && (
                    <>
                      <ProductRow font={pageSettings.itemDesign} index={5} />
                      <ProductRow font={pageSettings.itemDesign} index={6} />
                      <ProductRow font={pageSettings.itemDesign} index={7} />
                      <ProductRow font={pageSettings.itemDesign} index={8} />
                    </>
                  )}
                </div>
              </Rnd>
            ))}
            {currentPage.specialElements.map((element) => (
              <Rnd
                key={element.id}
                id={"gestaltung_cd"}
                className="image-draggable-container"
                size={{ width: element.width, height: element.height }}
                position={{ x: element.topLeftX, y: element.topLeftY }}
                bounds="parent"
                onDragStop={(e, data) => {
                  setSpecialElement({
                    ...element,
                    topLeftX: data.x,
                    topLeftY: data.y,
                  });
                }}
                onResizeStop={(e, direction, ref, delta, position) => {
                  setSpecialElement({
                    ...element,
                    width: parseInt(ref.style.width, 10),
                    height: parseInt(ref.style.height, 10),
                    topLeftX: position.x,
                    topLeftY: position.y,
                  });
                }}
              >
                {element.specialElementType ===
                SpecialElementType.SELECTOR_IMG ? (
                  <img
                    src={
                      element.imageExample
                        ? URL.createObjectURL(element.imageExample)
                        : process.env.PUBLIC_URL + "/assets/empty_drink.png"
                    }
                    draggable={false}
                    width="100%"
                    alt="Selected item"
                    className="image-special-image"
                  />
                ) : (
                  <p
                    className="image-draggable-text"
                    style={{
                      fontFamily: element.fontFamily,
                      fontSize: element.fontSize,
                      // color: element.color,
                      textTransform: element.isCapital ? "uppercase" : "none",
                      textAlign: element.textAlignment,
                    }}
                  >
                    {element.specialElementType === SpecialElementType.NUMBER
                      ? "123"
                      : "Vanilková"}
                  </p>
                )}
              </Rnd>
            ))}
          </>
        )}
      </div>
    </div>
  );
}

export default MainContent;
