import React from "react";
import {
  Button,
  ButtonGroup,
  Form,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import { FaAlignCenter, FaAlignLeft, FaAlignRight } from "react-icons/fa";
import {
  Font,
  FontFamily,
  getDefaultFont,
} from "../interfaces/api/TemplateResponse";

export interface Props {
  header: string;
  font?: Font;
  updateFont: (font: Partial<Font>) => void;
}

export default function FontsCard({
  header,
  font = getDefaultFont(),
  updateFont,
}: Props) {
  return (
    <InputGroup size="sm" className="flex-row w-100">
      <InputGroup.Text>{header}</InputGroup.Text>
      <Form.Select
        onChange={(event) =>
          updateFont({
            fontFamily: event.target.value as FontFamily,
          })
        }
        defaultValue={font.fontFamily}
        aria-label="Default select example"
      >
        {(Object.keys(FontFamily) as Array<keyof typeof FontFamily>).map(
          (key) => {
            return (
              <option key={key} value={key}>
                {FontFamily[key]}
              </option>
            );
          }
        )}
        <option value={""}>Vyberu vlastní</option>
      </Form.Select>
      {font.fontFamily === "" && (
        <input
          className="custom-font-input"
          onChange={(event) => {
            const target = event.target as HTMLInputElement;
            //TODO target.files?.[0] && setImage(target.files?.[0]);
          }}
          type="file"
          id="backgroundPicker"
          title="Vyberte font"
        />
      )}
      <FormControl
        aria-label="Default"
        aria-describedby="size-text"
        type="number"
        pattern="[0-9]*"
        onChange={(event) =>
          updateFont({
            fontSize: event.target.validity.valid
              ? parseInt(event.target.value, 10)
              : font.fontSize,
          })
        }
        defaultValue={font.fontSize}
      />
      <Form.Control
        type="color"
        id="exampleColorInput"
        defaultValue={font.color}
        onChange={(event) =>
          updateFont({
            color: event.target.value,
          })
        }
        title="Choose your color"
      />

      {font.margin ? (
        <>
          <span id="margin-text">Mezery</span>
          <FormControl
            aria-label="Default"
            aria-describedby="margin-text"
            type="number"
            pattern="[0-9]*"
            onChange={(event) =>
              updateFont({
                margin: event.target.validity.valid
                  ? parseInt(event.target.value, 10)
                  : font.margin,
              })
            }
            defaultValue={font.margin}
          />
        </>
      ) : (
        <ButtonGroup size="sm" className="mb-1" aria-label="Alignment">
          <Button
            onClick={() => updateFont({ textAlignment: "left" })}
            variant={font.textAlignment === "left" ? "primary" : "secondary"}
          >
            <FaAlignLeft />
          </Button>
          <Button
            onClick={() => updateFont({ textAlignment: "center" })}
            variant={font.textAlignment === "center" ? "primary" : "secondary"}
          >
            <FaAlignCenter />
          </Button>
          <Button
            onClick={() => updateFont({ textAlignment: "right" })}
            variant={font.textAlignment === "right" ? "primary" : "secondary"}
          >
            <FaAlignRight />
          </Button>
        </ButtonGroup>
      )}
    </InputGroup>
  );
}
