import axios from "axios";
import * as React from "react";
import { createContext, useContext, useEffect, useState } from "react";
import { EnumObject } from "../interfaces/api/EnumsObject";
import {
  TemplateDataItem,
  TemplateResponse,
} from "../interfaces/api/TemplateResponse";
import { LoginFormData } from "../interfaces/LoginFormData";
import { User } from "../interfaces/User";
import { setAxiosEnvironment } from "./api/domain";

export interface ApiContextProps {
  user?: User;
  templatesData: TemplateDataItem[];
  enums: EnumObject;
  login: (data: LoginFormData) => void;
  logout: () => void;
  uploadFont: (file: File) => void;
  getFont: () => void;
}

export const ApiContext = createContext<ApiContextProps>(undefined!);

export enum Endpoint {
  login = "user/login",
  enums = "published/enums",
  templates = "templates",
  fontcategory = "templates/{id}/category-font",
}
const credentialsKey = "credentials";

const ApiContextProvider: React.FunctionComponent<{
  children?: React.ReactNode;
}> = (props) => {
  const [user, setUser] = useState<User>();
  const [enums, setEnums] = useState<EnumObject>({ data: {} });
  const [templatesData, setTemplatesData] = useState<TemplateDataItem[]>([]);

  useEffect(() => {
    setAxiosEnvironment();
    const savedCredentials =
      sessionStorage.getItem(credentialsKey) ||
      localStorage.getItem(credentialsKey);
    if (savedCredentials) {
      setUser(JSON.parse(savedCredentials));
      getEnums();
      getTemplates();
    }
    // setAxiosFingerprint()
  }, []);

  const login = (data: LoginFormData) => {
    axios
      .post(Endpoint.login, { data })
      .then((userData) => {
        sessionStorage.setItem(credentialsKey, JSON.stringify(userData));
        setUser(userData.data);
        getEnums();
        getTemplates();
      })
      .catch((err) => console.log("User LOGIN FAILED", err));
  };

  const getEnums = () =>
    axios.get<EnumObject>(Endpoint.enums).then((data) => setEnums(data.data));

  const getTemplates = () =>
    axios
      .get<TemplateResponse>(Endpoint.templates)
      .then((data) => setTemplatesData(data.data.data.list));

  const logout = () => setUser(undefined);

  const getFont = () => {
    axios
      .get(Endpoint.fontcategory.replace("{id}", templatesData[0].id))
      .then((data) => {
        console.log("data", data);
      });
  };

  const uploadFont = (path: File) => {
    const data = new FormData();
    data.append("fontFile", path);
    data.append("fontType", "ttf");
    console.warn(path);
    axios
      //TODO
      .post(Endpoint.fontcategory.replace("{id}", templatesData[0].id), data, {
        // receive two parameter endpoint url ,form data
      })
      .then((res) => {
        // then print response status
        console.warn(res);
      });
  };

  return (
    <ApiContext.Provider
      value={{ user, enums, templatesData, login, logout, getFont, uploadFont }}
    >
      {props.children}
    </ApiContext.Provider>
  );
};

export const useApiContext = () => useContext(ApiContext);

export default ApiContextProvider;
