import React from "react";
import { Button, Card, CloseButton, Form, InputGroup } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import { v4 as uuidv4 } from "uuid";
import { useImageContext } from "../../contexts/ImageContext";
import { Page, SpecialElement } from "../../interfaces/api/TemplateResponse";
import { SpecialElementType } from "../../interfaces/SpecialElement";
import CoordinatesGrid from "../CoordinatesGrid";
import FontsCard from "../FontsCard";

type Props = { currentPage: Page };

export default function SpecialFiledAccordion({ currentPage }: Props) {
  const { setSpecialElement, removeSpecialElement } = useImageContext();

  const specialElements = currentPage.specialElements;

  const addNewElement = () => {
    let maxIx = 0;
    for (maxIx = 0; maxIx < specialElements.length; maxIx++) {
      const element = specialElements[maxIx];
      if (!element) {
        return;
      }
    }

    const setting: SpecialElement = {
      id: uuidv4(),
      topLeftX: 100,
      topLeftY: 100,
      height: 100,
      width: 100,
      specialElementType: SpecialElementType.NUMBER,
      color: "#000000",
      textAlignment: "left",
      fontFamily: "",
      fontSize: 26,
      isCapital: false,
      items: [],
      leftMargin: 0,
    };

    setSpecialElement(setting);
  };

  return (
    <Card className="p-0 mt-1">
      <Card.Header className="flex-row justify-content-between">
        Special Element&nbsp;&nbsp;&nbsp;
        <div>
          <Button onClick={addNewElement}>
            <FaPlus size={13} />
            &nbsp;Přidat
          </Button>
        </div>
      </Card.Header>
      <Card.Body className="p-0 m-0">
        {specialElements.map((element, index) => (
          <>
            <div className="flex-row">
              <CoordinatesGrid
                key={element.id}
                coordinate={element}
                updateItem={(data) =>
                  setSpecialElement({ ...element, ...data })
                }
              />
              <InputGroup size="sm" className="mb-1">
                <InputGroup.Text>Typ</InputGroup.Text>
                <Form.Select
                  onChange={(event) =>
                    setSpecialElement({
                      ...element,
                      specialElementType: event.target
                        .value as SpecialElementType,
                    })
                  }
                  defaultValue={element.specialElementType}
                  aria-label="Default select example"
                >
                  {(
                    Object.keys(SpecialElementType) as Array<
                      keyof typeof SpecialElementType
                    >
                  ).map((key) => {
                    return (
                      <option key={key} value={key}>
                        {SpecialElementType[key]}
                      </option>
                    );
                  })}
                </Form.Select>
                {element.specialElementType ===
                  SpecialElementType.SELECTOR_IMG && (
                  <input
                    className="custom-file-input"
                    onChange={(event) => {
                      const target = event.target as HTMLInputElement;
                      target.files?.[0] &&
                        setSpecialElement({
                          ...element,
                          imageExample: target.files[0],
                        });
                    }}
                    type="file"
                    id="backgroundPicker"
                    title=""
                  />
                )}
              </InputGroup>
              <CloseButton onClick={() => removeSpecialElement(element.id)} />
            </div>
            {element.specialElementType !== SpecialElementType.SELECTOR_IMG && (
              <FontsCard
                header="Font"
                font={element}
                updateFont={(data) =>
                  setSpecialElement({ ...element, ...data })
                }
              />
            )}
          </>
        ))}
      </Card.Body>
    </Card>
  );
}
