import * as React from "react";
import { createContext, useContext, useEffect, useState } from "react";
import useWindowDimensions from "../functions/useWindowDimensions";
import {
  Column,
  getDefaultTemplate,
  getDefaultTemplatePage,
  SpecialElement,
  TemplateDataItem,
} from "../interfaces/api/TemplateResponse";
import { WidthHeight } from "../interfaces/WidthHeight";
import { useApiContext } from "./ApiContext";

export interface ImageContextProps {
  setPage: (value: "frontPage" | "backPage") => void;
  pageSettings?: TemplateDataItem;
  page: "frontPage" | "backPage";
  setPageSettings: (settings: Partial<TemplateDataItem>) => void;
  setImage: (value: File | undefined) => void;
  imageDimension: WidthHeight;
  setColumn: (value: Column) => void;
  removeColumn: (id: string) => void;
  boardDimension: WidthHeight;
  setBoardDimension: (value: WidthHeight) => void;
  setSpecialElement: (value: SpecialElement) => void;
  removeSpecialElement: (id: string) => void;
  loadPageSettings: (id: string) => void;
}

export const ImageContext = createContext<ImageContextProps>(undefined!);

const ImageContextProvider: React.FunctionComponent<{
  children?: React.ReactNode;
}> = (props) => {
  const { templatesData } = useApiContext();

  const [currentTemplate, setCurrentTemplate] = useState<TemplateDataItem>();
  const { width } = useWindowDimensions();
  const [imageDimension, setImageDimension] = React.useState<WidthHeight>({
    width: 0,
    height: 0,
  });
  const [boardDimension, setBoardDimension] = React.useState<WidthHeight>({
    width: 0,
    height: 0,
  });
  const [page, setPage] =
    React.useState<keyof Pick<TemplateDataItem, "frontPage" | "backPage">>(
      "frontPage"
    );

  const currentPage =
    page === "frontPage"
      ? currentTemplate?.frontPage
      : currentTemplate?.backPage;
  useEffect(() => {
    if (currentPage?.backgroundImageFile) {
      var img = document.createElement("img");
      var blob = URL.createObjectURL(currentPage?.backgroundImageFile);
      img.src = blob;
      img.onload = function () {
        setImageDimension(img);
      };
    }
  }, [currentPage?.backgroundImageFile, width]);

  const loadPageSettings = (id: string) => {
    const find = templatesData.find((template) => template.id === id);
    if (find) {
      //TODO OVERWRITE
      setCurrentTemplate(find);
    }
  };

  const setPageSettings = (value: Partial<TemplateDataItem>) => {
    setCurrentTemplate((prev) =>
      prev
        ? {
            ...prev,
            ...value,
          }
        : getDefaultTemplate(value)
    );
  };

  const setImage = (image: File | undefined) => {
    if (page === "frontPage") {
      setCurrentTemplate((prevVal) =>
        prevVal
          ? {
              ...prevVal,
              frontPage: prevVal.frontPage
                ? { ...prevVal.frontPage, backgroundImageFile: image }
                : getDefaultTemplatePage({
                    type: "FRONT",
                    backgroundImageFile: image,
                  }),
            }
          : getDefaultTemplate({
              frontPage: getDefaultTemplatePage({
                type: "FRONT",
                backgroundImageFile: image,
              }),
            })
      );
    } else {
      setCurrentTemplate((prevVal) =>
        prevVal
          ? {
              ...prevVal,
              backPage: prevVal.backPage
                ? { ...prevVal.backPage, backgroundImageFile: image }
                : getDefaultTemplatePage({
                    type: "BACK",
                    backgroundImageFile: image,
                  }),
            }
          : getDefaultTemplate({
              backPage: getDefaultTemplatePage({
                type: "BACK",
                backgroundImageFile: image,
              }),
            })
      );
    }
  };

  const setColumn = (column: Column) => {
    setCurrentTemplate((prev) => {
      const pageColumns = prev?.[page]?.columns || [];
      if (pageColumns.some((item) => item.id === column.id)) {
        pageColumns.map((page) => (page.id === column.id ? column : page));
      } else {
        pageColumns.push(column);
      }

      const pageData = prev?.[page]
        ? { ...prev[page], columns: pageColumns }
        : getDefaultTemplatePage({ columns: pageColumns });

      return prev
        ? { ...prev, [page]: pageData }
        : getDefaultTemplate({
            [page]: pageData,
          });
    });
  };

  const removeColumn = (id: string) => {
    setCurrentTemplate((prev) => {
      const pageColumns = prev?.[page]?.columns || [];
      pageColumns.filter((page) => page.id !== id);

      const pageData = prev?.[page]
        ? { ...prev[page], columns: pageColumns }
        : getDefaultTemplatePage({ columns: pageColumns });

      return prev
        ? { ...prev, [page]: pageData }
        : getDefaultTemplate({
            [page]: pageData,
          });
    });
  };

  const setSpecialElement = (column: SpecialElement) => {
    setCurrentTemplate((prev) => {
      const specialElements = prev?.[page]?.specialElements || [];
      if (specialElements.some((item) => item.id === column.id)) {
        specialElements.map((page) => (page.id === column.id ? column : page));
      } else {
        specialElements.push(column);
      }

      const pageData = prev?.[page]
        ? { ...prev[page], specialElements }
        : getDefaultTemplatePage({ specialElements });

      return prev
        ? { ...prev, [page]: pageData }
        : getDefaultTemplate({
            [page]: pageData,
          });
    });
  };
  const removeSpecialElement = (id: string) => {
    setCurrentTemplate((prev) => {
      const specialElements = prev?.[page]?.specialElements || [];
      specialElements.filter((page) => page.id !== id);

      const pageData = prev?.[page]
        ? { ...prev[page], specialElements }
        : getDefaultTemplatePage({ specialElements });

      return prev
        ? { ...prev, [page]: pageData }
        : getDefaultTemplate({
            [page]: pageData,
          });
    });
  };

  return (
    <ImageContext.Provider
      value={{
        pageSettings: currentTemplate,
        page,
        setPage,
        setPageSettings,
        imageDimension,
        setImage,
        setColumn,
        removeColumn,
        boardDimension,
        setBoardDimension,
        loadPageSettings,
        setSpecialElement,
        removeSpecialElement,
      }}
    >
      {props.children}
    </ImageContext.Provider>
  );
};

export const useImageContext = () => useContext(ImageContext);

export default ImageContextProvider;
