import React, { ChangeEvent } from "react";
import {
  Button,
  ButtonGroup,
  Form,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import { useApiContext } from "../../contexts/ApiContext";
import { useImageContext } from "../../contexts/ImageContext";
import { TemplateDataItem } from "../../interfaces/api/TemplateResponse";

export default function PaperAccordion() {
  const { enums } = useApiContext();
  const { pageSettings, page, setPage, setPageSettings } = useImageContext();

  const createNewTemplate = () => {
    setPageSettings({});
  };

  const handleChange =
    (key: keyof TemplateDataItem) => (evt: ChangeEvent<HTMLInputElement>) => {
      const item = evt.target.validity.valid ? evt.target.value : undefined;
      if (item) {
        const parsedItem = parseInt(item, 10);

        setPageSettings({ [key]: parsedItem });
      }
    };
  if (pageSettings) {
    return (
      <InputGroup size="sm" className="flex-row w-100">
        <InputGroup.Text className="group-title">Plátno</InputGroup.Text>
        <InputGroup.Text>Šířka</InputGroup.Text>
        <FormControl
          className="paper-size-input"
          type="number"
          pattern="[0-9]*"
          onChange={handleChange("width")}
          value={pageSettings?.width}
        />
        <InputGroup.Text>mm</InputGroup.Text>
        <InputGroup.Text id="height">Výška</InputGroup.Text>
        <FormControl
          aria-describedby="height"
          className="paper-size-input"
          type="number"
          pattern="[0-9]*"
          onChange={handleChange("height")}
          value={pageSettings?.height}
        />
        <InputGroup.Text>mm</InputGroup.Text>
        <InputGroup.Text>Druh</InputGroup.Text>
        <Form.Select
          onChange={(event) =>
            setPageSettings({ templateType: event.target.value })
          }
          defaultValue={pageSettings?.templateType}
          aria-label="Typ Menu"
        >
          <option key="" value="">
            -
          </option>
          {enums.data["menuType"]?.map((format) => (
            <option key={format.code} value={format.code}>
              {format.text}
            </option>
          ))}
        </Form.Select>
        <ButtonGroup size="sm">
          <Button
            onClick={() => setPage("frontPage")}
            variant={page === "frontPage" ? "primary" : "secondary"}
          >
            Přední strana
          </Button>
          <Button
            onClick={() => setPage("backPage")}
            variant={page === "backPage" ? "primary" : "secondary"}
          >
            Zadní strana
          </Button>
        </ButtonGroup>
      </InputGroup>
    );
  } else {
    return <Button onClick={createNewTemplate}>Vytvořit nový template</Button>;
  }
}
