import React from "react";
import { Col, Row } from "react-bootstrap";
import { Font } from "../interfaces/api/TemplateResponse";

interface Props {
  font: Font;
  index: number;
}

function ProductRow(props: Props) {
  const { font, index } = props;

  return (
    <Row style={{ marginTop: font.margin }}>
      <Col md={8}>
        <p
          className="column-text"
          style={{
            fontFamily: font.fontFamily,
            fontSize: font.fontSize,
            color: font.color,
            textTransform: font.isCapital ? "uppercase" : "none",
          }}
        >
          Jmeno produktu {index}
        </p>
      </Col>
      <Col md={2} className="text-nowrap">
        <p
          className="column-text text-nowrap align-right"
          style={{
            fontFamily: font.fontFamily,
            fontSize: font.fontSize,
            color: font.color,
            textTransform: font.isCapital ? "uppercase" : "none",
          }}
        >
          0,04 l
        </p>
      </Col>
      <Col md={2}>
        <p
          className="column-text text-nowrap"
          style={{
            fontFamily: font.fontFamily,
            fontSize: font.fontSize,
            color: font.color,
            textTransform: font.isCapital ? "uppercase" : "none",
          }}
        >
          120,-
        </p>
      </Col>
    </Row>
  );
}

export default ProductRow;
