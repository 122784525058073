import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import "./App.css";
import Header from "./components/Header";
import LeftNavbar from "./components/LeftNavbar";
import MainContent from "./components/MainContent";
import ApiContextProvider, { ApiContext } from "./contexts/ApiContext";
import ImageContextProvider from "./contexts/ImageContext";

function App() {
  return (
    <div className="App">
      <ApiContextProvider>
        <Header />
        <ApiContext.Consumer>
          {({ user }) => {
            // if (user) {
            return (
              <ImageContextProvider>
                <div className="flex-row">
                  <LeftNavbar></LeftNavbar>
                  <MainContent></MainContent>
                </div>
              </ImageContextProvider>
            );
            // } else {
            //   <MainContent>
            //     <LoginForm />
            //   </MainContent>;
            // }
          }}
        </ApiContext.Consumer>
      </ApiContextProvider>
    </div>
  );
}

export default App;
