import React, { ChangeEvent } from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import { Column, Coordinates } from "../interfaces/api/TemplateResponse";

type Props = {
  coordinate: Coordinates;
  updateItem: (element: Coordinates) => void;
  maxWidth?: number;
  maxHeight?: number;
};

export default function CoordinatesGrid({
  coordinate,
  maxHeight,
  maxWidth,
  updateItem,
}: Props) {
  const updateValue =
    (type: keyof Column) => (evt: ChangeEvent<HTMLInputElement>) => {
      const item = evt.target.validity.valid ? evt.target.value : undefined;
      if (item) {
        const parsedItem = parseInt(item, 10);
        updateItem({ ...coordinate, [type]: parsedItem });
      }
    };

  return (
    <InputGroup size="sm" className="flex-row w-100">
      <InputGroup.Text>X:</InputGroup.Text>
      <FormControl
        className="column-input"
        type="number"
        step={1}
        pattern="[0-9]*"
        onChange={updateValue("topLeftX")}
        value={coordinate.topLeftX}
      />
      <InputGroup.Text>Y:</InputGroup.Text>

      <FormControl
        className="column-input"
        type="number"
        step={1}
        pattern="[0-9]*"
        onChange={updateValue("topLeftY")}
        value={coordinate.topLeftY}
      />
      <InputGroup.Text>Šířka:</InputGroup.Text>
      <FormControl
        className="column-input"
        type="number"
        step={1}
        pattern="[0-9]*"
        max={maxWidth}
        onChange={updateValue("width")}
        value={coordinate.width}
      />
      <InputGroup.Text>Výška:</InputGroup.Text>

      <FormControl
        className="column-input"
        type="number"
        pattern="[0-9]*"
        step={1}
        max={maxHeight}
        onChange={updateValue("height")}
        value={coordinate.height}
      />
    </InputGroup>
  );
}
