import React from "react";
import { Button, Card, CloseButton } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import { v4 as uuidv4 } from "uuid";
import { useImageContext } from "../../contexts/ImageContext";
import { Column, Font, Page } from "../../interfaces/api/TemplateResponse";
import CoordinatesGrid from "../CoordinatesGrid";
import FontsCard from "../FontsCard";

export interface ColumnsProps {
  currentPage: Page;
}
export default function ColumnsAccordion({ currentPage }: ColumnsProps) {
  const {
    setPageSettings,
    pageSettings,
    removeColumn,
    setColumn,
    boardDimension,
  } = useImageContext();
  const addNewColumn = () => {
    if (!currentPage) return;
    let maxIx = currentPage.columns.length;

    const prevCol = maxIx > 0 ? currentPage.columns[maxIx - 1] : undefined;

    const setting: Column = {
      id: uuidv4(),
      order: maxIx,
      topLeftX: prevCol
        ? prevCol.topLeftX + prevCol.width + 10
        : currentPage.margins.left + 10,
      topLeftY: prevCol ? prevCol.topLeftY : currentPage.margins.top + 10,
      height: prevCol
        ? prevCol.height
        : boardDimension.height -
          currentPage.margins.top -
          currentPage.margins.bottom -
          20,
      width: prevCol
        ? prevCol.width
        : (boardDimension.width -
            currentPage.margins.left -
            currentPage.margins.right) /
            2 -
          20,
    };

    setColumn(setting);
  };

  const updateFont =
    (type: "categoryDesign" | "subcategoryDesign" | "itemDesign") =>
    (value: Partial<Font>) => {
      if (pageSettings?.[type]) {
        setPageSettings({
          [type]: { ...pageSettings?.categoryDesign, ...value },
        });
      }
    };

  return (
    <Card className="p-0 mt-1">
      <Card.Header className="flex-row justify-content-between">
        Sloupce
        <div>
          <Button onClick={addNewColumn}>
            <FaPlus size={13} />
            &nbsp;Přidat
          </Button>
        </div>
      </Card.Header>
      <Card.Body className="p-0 m-0">
        <FontsCard
          header={"Nadpis"}
          font={pageSettings?.categoryDesign}
          updateFont={updateFont("categoryDesign")}
        />
        <FontsCard
          header={"Podnadpis"}
          font={pageSettings?.subcategoryDesign}
          updateFont={updateFont("subcategoryDesign")}
        />
        <FontsCard
          header={"Text"}
          font={pageSettings?.itemDesign}
          updateFont={updateFont("itemDesign")}
        />
        <div>
          {currentPage?.columns?.map((column, index) => {
            return (
              <div className="flex-row ">
                <span className="text-nowrap p-1">
                  Sloupec {column.order + 1}
                </span>
                <CoordinatesGrid
                  coordinate={column}
                  updateItem={(data) => setColumn({ ...column, ...data })}
                />
                <CloseButton
                  className="p-1"
                  onClick={() => removeColumn(column.id)}
                />
              </div>
            );
          })}
        </div>
      </Card.Body>
    </Card>
  );
}
